
<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px; padding: 20px">
    <div class="row align-items-start">
      <UserInfo></UserInfo>
      <OrderMenu></OrderMenu>
    </div>

    <div class="col">
      <div v-bind:class="darkMode" class="card p-3 d-flex align-items-center">Copyright {{ new Date().getFullYear() }} - Ashleys Party &copy;</div>
    </div>
  </div>
</template>

<script>
import { darkModeStyle } from '../../assets/js/darkMode';

export default {
  name: "Home",
  components: {
    UserInfo: () => import("./User.vue"),
    OrderMenu: () => import("./Featured.vue"),
  },
  data() {
    return {
      darkMode: darkModeStyle(),
    }
  }
};
</script>